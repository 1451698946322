@base: 100;

.guide-banner{
  width: 100%;
}

.guide-container{
  margin-top: 20rem/@base;
  .guide-container-item{
    margin-top: 60rem/@base;
    .guide-container-item-label{
      color: #33353D;
      font-weight: bold;
    }
    .guide-container-item-text{
      margin-left: 18px;
    }
  }
  .guide-container-item-label{
    color: #33353D;
    font-weight: bold;
  }
}

.title1 {
  font-size: 24rem/@base;
  font-weight: bold;
}

.title2 {
  font-size: 20rem/@base;
  font-weight: bold;
}

.containerContent {
  font-size: 16rem/@base;
  color: #33353D;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 30px;
}

.guide-anchor {
  > .ant-anchor {
    font-size: 14rem/@base;

    .ant-anchor-ink-ball {
      border-radius: unset;
      border: unset;
      width: 2px;
      background: #FAA700;
    }
    >.ant-anchor-link {
      .ant-anchor-link-title {
        &:hover {
          color: #FAA700;
        }
      }
      >.ant-anchor-ink-ball {
        border-radius: unset;
        border: unset;
        width: 2px;
      }
    }
    .ant-anchor-link-active {
      .ant-anchor-link-title-active {
        color: #FAA700;

      }
    }
  }
}