@import '~antd/dist/antd.css';

@base: 100;
.form-item:before {
  content: '* ';
  color: red;
}

.loginAvatar {
  height: 8.4vh;
  line-height: 8.4vh;
  display: flex;
  max-width: 258rem/@base;

  p {
    margin: 0;
    height: 8.4vh;
    line-height: 8.4vh;
    margin-right: 6rem/@base;
  }

  span {
    color: rgba(242, 242, 240, 1);
    cursor: pointer;
  }

  .logoutClass {
    color: rgba(166, 165, 162, 1);
  }

  img {
    width: 40rem/@base;
    border-radius: 50%;
    margin-right: 10rem/@base;
  }
}

.headerWrap {
  background-color: #353F66;
  width: 100%;
  height: 5.2vh;
  padding: 0 80rem/@base 0 3.6rem;
  display: flex;
  flex-direction: row;

  .logoImg {
    width: 0.86rem;
    margin: 0 1rem .1rem 0;
    margin-right: 8rem;
  }

  .div {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    // margin-top: 16rem/@base;
    p {
      width: 160rem/@base;
      position: absolute;
      right: 180rem/@base;
      top: 50%; /*偏移*/
      transform: translateY(-50%);

      img {
        width: 36rem/@base;
        margin-right: 8rem/@base;
        border-radius: 50%;
      }

      span {
        color: rgba(242, 242, 240, 1);
        font-size: 16rem/@base;
      }
    }

    .languageSwitch {
      color: rgba(242, 242, 240, 1);
      width: 120rem/@base;
      // height:48rem/@base;

      margin-right: 20rem/@base;
      background-color: transparent;

      svg {
        color: rgba(242, 242, 240, 1) !important;
      }

      position: absolute;
      right: 180rem/@base;
      top: 50%; /*偏移*/
      transform: translateY(-50%)
    }
  }

  .toOfficalWeb {
    display: block;
    text-align: center;
    width: 120rem/@base;
    height: 32rem/@base;
    line-height: 32rem/@base;
    background: #FDCA40;
    font-size: 16rem/@base;
    color: rgba(72, 15, 14, 1);
    position: absolute;
    top: 50%; /*偏移*/
    right: 0rem/@base;
    transform: translateY(-50%)
  }
}

input::-webkit-input-placeholder {
  color: rgba(166, 165, 162, 1);
  font-size: 12rem/@base;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: rgba(166, 165, 162, 1);
  font-size: 12rem/@base;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: rgba(166, 165, 162, 1);
  font-size: 12rem/@base;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(166, 165, 162, 1);
  font-size: 12rem/@base;
}

.ant-select-selection {
  // border: none;
  // background-color: transparent;
  // outline: none;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  // background-color: rgba(255, 255, 255, .1);
}

.ant-select-dropdown {
  // background-color: rgba(0, 0, 0, .3);
  // opacity: .6;

  .optItem {
    background-color: transparent;
    color: rgba(242, 242, 240, 1);
  }
}

.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  border: none;
}

.ant-table-pagination.ant-pagination {
  margin: 16rem/@base 16rem/@base;
}

.word-wrap {
  word-break: break-all;
}

.tableBtn {
  width: auto;
  height: 24rem/@base;
  border-radius: 14rem/@base;
  border: 1rem/@base solid rgba(151, 151, 151, 1);
  cursor: pointer;
  outline: none;
  background: #fff;
  color: #4A4B4D;
  padding: 12rem/@base 13rem/@base;
  line-height: 0rem;
  margin: 0;
  margin-right: .2rem;
  font-size: 14rem/@base;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
}

.verifyCode {
  display: flex;
  flex-direction: row;
  padding-left: .2rem;

  input {
    width: 138rem/@base !important;
  }

  button {
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    border: 1px solid rgba(255, 177, 0, 1);
    margin: 4rem/@base 0 0 8rem/@base;
    width: 100rem/@base;
    height: 28rem/@base;
    font-size: 12rem/@base;
    font-family: PingFangSC-Regular, PingFangSC;
    padding: 0;
    color: rgba(255, 177, 0, 1);
    line-height: 17px;
    cursor: pointer;
  }
}

.button {
  width: 160rem/@base;
  height: 40rem/@base;
  background: rgba(255, 177, 0, 1);
  border-radius: 4rem/@base;
  border: none;
  color: #33353D;
  font-weight: 500;
  font-size: 16rem/@base;
  cursor: pointer;
  outline: none;
  margin-bottom: 60rem/@base;
}

code {
  display: block;
}

.navWrap {
  font-size: 16rem/@base;
  color: rgba(242, 242, 240, 1);
  margin-right: 430rem/@base;

  span {
    display: inline-block;
    height: 8.4vh;
    line-height: 8.4vh;
    margin-right: 40rem/@base;
    color: #F2F2F0;
    cursor: pointer;
  }
}

.imgP {
  font-size: .12rem !important;
  font-family: PingFangSC-Regular, PingFangSC !important;
  color: #A6A5A2 !important;
  margin: 6rem/@base 0 !important;
  border: none !important;
  text-align: left;
}

.uploadImg {
  display: flex;
  flex-direction: row;
  margin-left: 50rem/@base;
  margin-top: 28rem/@base;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  .cover-uploader {
    display: inline-block;
    border: 1rem/@base dashed #d9d9d9;
    margin-bottom: 0;
    margin-top: .01rem;
    width: 345rem/@base;

    height: 190rem/@base;
    text-align: center;
    font-size: 14rem/@base;

    .imgUploadText {
      strong {
        display: block;
        margin: 0 auto;
        margin-top: 23rem/@base;
        margin-bottom: 12rem/@base;
        border-radius: 2rem/@base;
        background: url('./../images/circle.png') no-repeat;
        background-size: 100%;
        width: 68rem/@base;
        height: 68rem/@base;
      }

      p {
        width: 100%;
        font-size: 16rem/@base;
        font-family: PingFangSC-Regular, PingFangSC;
        color: rgba(77, 74, 74, 1);
        line-height: 22rem/@base;
        margin: 0;
        padding: 0;
        text-align: center;
      }

      span {
        font-size: 12rem/@base;
        font-family: PingFangSC-Regular, PingFangSC;
        color: rgba(166, 165, 162, 1);
        line-height: 17rem/@base;
      }
    }
  }
}

.content-wrap {
  width: 100%;
  background-color: #FFF;
  height: 91.6vh;
  overflow: auto;

  .editWrap {
    width: 1200rem/@base;
    background-color: #fff;
    //margin: 0 auto;
    overflow: auto;
    margin-top: 121rem/@base;
    //box-shadow:0 8rem/@base 8rem/@base 0 rgba(35,26,21,0.08);
    .switchAV {

      box-shadow: 0px 1px 1px 0px rgba(230, 229, 227, 1);
      border-radius: 4rem/@base 4rem/@base 0px 0px;
    }

    .uploadSpan {
      display: inline-block;
      text-align: center;
      width: 180rem/@base;
      height: 60rem/@base;
      font-size: 18rem/@base;
      font-family: PingFangSC-Medium, PingFangSC;
      font-weight: 500;
      color: rgba(77, 74, 74, 1);
      line-height: 60rem/@base;
      cursor: pointer;
      // outline: 1rem/@base solid red;
    }

    .warningP {
      width: 100%;
      height: 30rem/@base;
      background: rgba(255, 104, 48, 0.1);
      font-size: 12rem/@base;
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      color: rgba(255, 104, 48, 1);
      padding-left: 20rem/@base;
      line-height: 30rem/@base;
    }

    .approveWrap {
      text-align: center;
      margin-top: 152rem/@base;

      p img {
        width: 166rem/@base;
      }

      p {
        font-size: 12rem/@base;
        font-family: PingFangSC-Regular, PingFangSC;
        color: rgba(166, 165, 162, 1);
      }
    }

    .step-wrap {
      text-align: center;
      min-height: 695rem/@base;

      .step-p {
        height: 30rem/@base;
        line-height: 30rem/@base;
        background: rgba(252, 251, 251, 1);
        font-size: 12rem/@base;
        font-family: PingFangSC-Regular, PingFangSC;
        color: #A6A5A2;
        margin-bottom: 60rem/@base;

        span {
          display: inline-block;
        }
      }


      .approveStep1{
        display: flex;
        flex-direction: row;
        margin-bottom: 133rem/@base;
        .approveWrap{
          display: flex;
          align-items: center;
          width: 580rem/@base;
          height: 272rem/@base;
          background: #F7F8FA;
          padding: .24rem .24rem;

          .approveWrap-img{
            width: 200rem/@base;
            height: 152rem/@base;
            img{
              width: 200rem/@base;
              height: 152rem/@base;
            }
          }
          .approveWrap-content{
            display: inline-flex;
            flex-direction: column;
            text-align: left;
            width: 200rem/@base;
            height: 152rem/@base;
            margin-left: 24rem/@base;
            font-size: 14rem/@base;
            .approveWrap-content-author{
              height: 22rem/@base;
              font-size: 16rem/@base;
              font-weight: bold;
              margin-bottom: 12rem/@base;
            }
            .approveWrap-content-des{
              color: #6C6E7A;
              margin-bottom: 24rem/@base;
            }
            .approveWrap-content-btn{
              width: 200rem/@base;
              height: 36rem/@base;
              background: #FDCA40;
              border-radius: 4rem/@base;
              border: none;
              color: #33353D;
              font-size: 14rem/@base;
              cursor: pointer;
              outline: none;
            }
          }
          //img{
          //  width: 136rem/@base
          //}
          //p{
          //  font-size:14rem/@base;
          //  line-height:20rem/@base;
          //  color:rgba(166,165,162,1);
          //  margin-bottom: 20rem/@base;
          //}
          //span{
          //  color:rgba(77,74,74,1);
          //  font-size:20rem/@base;
          //  line-height:28rem/@base;
          //  margin-bottom: 4rem/@base;
          //}
        }
      }
      .approveStep2 {
        width: 360rem/@base;

        margin: 0 auto;
        margin-top: 66rem/@base;

        .uploadImg {
          margin-left: 16rem/@base;

          .cover-uploader {
            width: 240rem/@base;
            height: 150rem/@base;

            .imgUploadText {
              i {
                display: block;
                margin: 0 auto;
                // margin-top: 23rem/@base;
                margin-bottom: 12rem/@base;
                border-radius: 2rem/@base;
                background: url('./../images/uploadImg.png') no-repeat;
                background-size: 100%;
                width: 105rem/@base;
                height: 74rem/@base;
              }
            }
          }
        }

        p {
          font-size: 14rem/@base;
          font-family: PingFangSC-Regular, PingFangSC;
          color: rgba(36, 35, 35, 1);
          margin-bottom: 28rem/@base;

          input {
            outline: none;
            border-radius: 2rem/@base;
            border: 1rem/@base solid rgba(230, 229, 227, 1);
            width: 240rem/@base;
            height: 36rem/@base;
            padding-left: 10rem/@base;
          }
        }
      }

      .approveStep3 {
        display: flex;
        justify-content: center;

        img {
          width: 146rem/@base;
        }

        .approveStep3-text {
          margin-left: 0.24rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          span {
            font-size: 24rem/@base;
            font-weight: bold;
            color: #33353D;
          }

          p {
            font-size: 14rem/@base;
            margin-top: 16rem/@base;
            color: #33353D;
          }
        }
      }
    }

    .vedioWrap {
      margin-top: 20rem/@base;

      video {
        width: 480rem/@base;
        height: 270rem/@base;
        margin-left: 58rem/@base;
      }

      .p {
        margin-left: 50rem/@base;

        span {
          margin-left: 20rem/@base;
        }

        // input {
          // height: 44rem/@base;
          // padding: 0;
          // padding-left: 10rem/@base;
          // border: none;
          // outline: none;
          // border-radius: 4rem/@base;
          // border: 1rem/@base solid rgba(230, 229, 227, 1);
        // }
      }

      .article-tip {
        margin-left: 150rem/@base;
        color: red; 
      }

      .uploadVideo {
        margin-left: 50rem/@base;
        margin-top: 28rem/@base;
        display: flex;
        flex-direction: row;

        .uploadVideoChild {
          display: flex;
          flex-direction: column;
        }

        .dragEle {
          display: inline-block;
          // border:1rem/@base solid rgba(230,229,227,1);
          margin-bottom: 0;
          border-radius: 2rem/@base;
          width: 480rem/@base;
          height: 270rem/@base;
          text-align: center;
          font-size: 14rem/@base;
          position: relative;

          strong {
            display: block;
            margin: 0 auto;
            margin-bottom: 12rem/@base;
            border-radius: 2rem/@base;
            background: url('./../images/uploadVideo.png') no-repeat;
            background-size: 100%;
            width: 102rem/@base;
            height: 98rem/@base;
          }

          input {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 480rem/@base;
            height: 270rem/@base;
            filter: alpha(opacity=0);
            cursor: pointer
          }

          span {
            font-size: 12rem/@base;
            color: rgba(166, 165, 162, 1);
          }

          .compressNode {
            display: block;
            font-size: 12rem/@base;
            margin-top: 28rem/@base;
          }

          a {
            color: #FFDF99;
            text-decoration: none;
          }
        }
      }
    }

    .preViewArticle {
      position: relative;
      width: 480rem/@base;
      height: 450rem/@base;
      background: rgba(255, 255, 255, 1);
      margin-left: 58rem/@base;

      .proviewSpan {
        position: absolute;
        display: inline-block;
        font-size: 12rem/@base;
        font-family: PingFangSC-Regular, PingFangSC;
        color: rgba(77, 74, 74, 1);
        width: 24rem/@base;
        height: 52rem/@base;
        background: rgba(241, 241, 241, 1);
        border: 1rem/@base solid rgba(204, 204, 204, 1);
        border-right: null;
        text-align: center;
        left: -24rem/@base;
      }
    }
  }

  .appdownload {
    height: 100%;
    width: 100%;
    background: url('./../images/appdown.png');
    background-size: 100% 100%;
    overflow: hidden;

    .qrWrap {
      width: 320rem/@base;
      height: 398rem/@base;
      background: rgba(0, 0, 0, .5);
      border: 2rem/@base solid rgba(255, 177, 0, .5);
      margin: 200rem/@base 0 0 560rem/@base;
      text-align: center;
      position: relative;

      .node {
        font-size: 14rem/@base;
        color: rgba(230, 229, 227, .5);
        margin-top: 6rem/@base;
        margin-bottom: 0;
      }

      h3 {
        margin-top: 10rem/@base;
        font-size: 28rem/@base;
        font-weight: 500;
        color: rgba(242, 242, 240, 1);
        padding: 0;
      }

      p {
        font-size: 20rem/@base;
        font-weight: 500;
        color: rgba(242, 242, 240, 1);
      }

      img {
        width: 180rem/@base;
        margin-top: 60rem/@base;
      }
    }
  }

}

