@base: 100;
.header-layout-header-wrap{
  background-color:#353F66;
  width:100%;
  height:5.2vh;
  line-height: 5.2vh;
  color: #F2F2F0;
  font-size: 16rem/@base;
  text-align: center;

  .header-layout-header-logo{
    //height: 42rem/@base;
    //line-height: 42rem/@base;
    //margin-left: 48rem/@base;
  }
  .header-layout-header-content{
    //width: 1400rem/@base;
    //margin-left: 120rem/@base;
  }
  .header-layout-header-content-item{
    height: 100%;
    cursor: pointer;
  }

  .header-layout-header-content-item-web{
    background: #FDCA40;
    color: #353F66;
    padding: 0.05rem 0.27rem;
  }

  .header-layout-header-login{
    cursor: pointer;
  }

}
