@import '~antd/dist/antd.css';
.form-item:before {
  content: '* ';
  color: red;
}
.loginAvatar {
  height: 8.4vh;
  line-height: 8.4vh;
  display: flex;
  max-width: 2.58rem;
}
.loginAvatar p {
  margin: 0;
  height: 8.4vh;
  line-height: 8.4vh;
  margin-right: 0.06rem;
}
.loginAvatar span {
  color: #f2f2f0;
  cursor: pointer;
}
.loginAvatar .logoutClass {
  color: #a6a5a2;
}
.loginAvatar img {
  width: 0.4rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}
.headerWrap {
  background-color: #242323;
  width: 100%;
  height: 8.4vh;
  padding: 0 0.8rem 0 3.6rem;
  display: flex;
  flex-direction: row;
}
.headerWrap .logoBox{
  display: flex;
  align-items: center;
}
.headerWrap .logoBox .logoImg {
  width: 1.13rem;
  margin: 0 1rem 0.1rem 0;
  /*margin-right: 8rem;*/
}
.headerWrap .tabs{
  width:4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f2f2f0;
}
.headerWrap .tabs div{
  cursor: pointer;
}

.headerWrap .div {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}
.headerWrap .div p {
  width: 1.6rem;
  position: absolute;
  right: 1.8rem;
  top: 50%;
  /*偏移*/
  transform: translateY(-50%);
}
.headerWrap .div p img {
  width: 0.36rem;
  margin-right: 0.08rem;
  border-radius: 50%;
}
.headerWrap .div p span {
  color: #f2f2f0;
  font-size: 0.16rem;
}
.headerWrap .div .languageSwitch {
  color: #f2f2f0;
  width: 1.2rem;
  margin-right: 0.2rem;
  background-color: transparent;
  position: absolute;
  right: 1.8rem;
  top: 50%;
  /*偏移*/
  transform: translateY(-50%);
}
.headerWrap .div .languageSwitch svg {
  color: #f2f2f0 !important;
}
.headerWrap .toOfficalWeb {
  display: block;
  text-align: center;
  width: 1.8rem;
  height: 0.48rem;
  line-height: 0.48rem;
  background: #ceb06e;
  font-size: 0.2rem;
  color: #480f0e;
  position: absolute;
  top: 50%;
  /*偏移*/
  right: 0rem;
  transform: translateY(-50%);
}
.headerWrap .mobileItem{
  display: none;
}
input::-webkit-input-placeholder {
  color: #a6a5a2;
  font-size: 0.12rem;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a6a5a2;
  font-size: 0.12rem;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a6a5a2;
  font-size: 0.12rem;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a6a5a2;
  font-size: 0.12rem;
}
.ant-select-selection {
  /* border: none; */
  /* background-color: transparent; */
  /* outline: none; */
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  /* background-color: rgba(255, 255, 255, 0.1); */
}
.ant-select-dropdown {
  /* background-color: rgba(0, 0, 0, 0.3); */
}
.ant-select-dropdown .optItem {
  /* background-color: transparent; */
  color: #f2f2f0;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0;
}
.ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  border: none;
}
.ant-table-pagination.ant-pagination {
  margin: 0.16rem 0.16rem;
}
.word-wrap {
  word-break: break-all;
}
.tableBtn {
  width: auto;
  height: 0.24rem;
  border-radius: 0.14rem;
  border: 0.01rem solid #979797;
  cursor: pointer;
  outline: none;
  background: #fff;
  color: #4A4B4D;
  padding: 0.12rem 0.13rem;
  line-height: 0rem;
  margin: 0;
  margin-right: 0.2rem;
  font-size: 0.14rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-variant: tabular-nums;
}
.verifyCode {
  display: flex;
  flex-direction: row;
  padding-left: 0.2rem;
}
.verifyCode input {
  width: 1.38rem !important;
}
.verifyCode button {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ffb100;
  margin: 0.04rem 0 0 0.08rem;
  width: 1rem;
  height: 0.28rem;
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  padding: 0;
  color: #ffb100;
  line-height: 17px;
  cursor: pointer;
}
.button {
  width: 1.6rem;
  height: 0.4rem;
  background: #ffb100;
  border-radius: 0.04rem;
  background-color: '#FFB100';
  border: none;
  color: white;
  font-weight: 500;
  font-size: 0.16rem;
  cursor: pointer;
  outline: none;
  margin-bottom: 0.6rem;
}
code {
  display: block;
}
.navWrap {
  font-size: 0.16rem;
  color: #f2f2f0;
  margin-right: 4.3rem;
}
.navWrap span {
  display: inline-block;
  height: 8.4vh;
  line-height: 8.4vh;
  margin-right: 0.4rem;
  color: #F2F2F0;
  cursor: pointer;
}
.imgP {
  font-size: 0.12rem !important;
  font-family: PingFangSC-Regular, PingFangSC !important;
  color: #A6A5A2 !important;
  margin: 0.06rem 0 !important;
  border: none !important;
  text-align: left;
}
.uploadImg {
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  margin-top: 0.28rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.uploadImg .cover-uploader {
  display: inline-block;
  border: 0.01rem dashed #d9d9d9;
  margin-bottom: 0;
  margin-top: 0.01rem;
  width: 3.45rem;
  height: 1.9rem;
  text-align: center;
  font-size: 0.14rem;
}
.uploadImg .cover-uploader .imgUploadText strong {
  display: block;
  margin: 0 auto;
  margin-top: 0.23rem;
  margin-bottom: 0.12rem;
  border-radius: 0.02rem;
  background: url('../images/circle.png') no-repeat;
  background-size: 100%;
  width: 0.68rem;
  height: 0.68rem;
}
.uploadImg .cover-uploader .imgUploadText p {
  width: 100%;
  font-size: 0.16rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #4d4a4a;
  line-height: 0.22rem;
  margin: 0;
  padding: 0;
  text-align: center;
}
.uploadImg .cover-uploader .imgUploadText span {
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #a6a5a2;
  line-height: 0.17rem;
}
.content-wrap {
  width: 100%;
  background-color: #F7F6F5;
  height: 94.8vh;
  overflow: auto;
}
.content-wrap .editWrap {
  width: 12rem;
  background-color: #fff;
  margin: 0 auto;
  /*overflow: auto;*/
  margin-top: 0.4rem;
  /*box-shadow: 0 0.08rem 0.08rem 0 rgba(35, 26, 21, 0.08);*/
}
.content-wrap .editWrap .switchAV {
  box-shadow: 0px 1px 1px 0px #e6e5e3;
  border-radius: 0.04rem 0.04rem 0px 0px;
}
.content-wrap .editWrap .uploadSpan {
  display: inline-block;
  text-align: center;
  width: 1.8rem;
  height: 0.6rem;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFangSC;
  font-weight: 500;
  color: #4d4a4a;
  line-height: 0.6rem;
  cursor: pointer;
}
.content-wrap .editWrap .warningP {
  width: 100%;
  height: 0.3rem;
  background: rgba(255, 104, 48, 0.1);
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  font-weight: 400;
  color: #ff6830;
  padding-left: 0.2rem;
  line-height: 0.3rem;
}
.content-wrap .editWrap .approveWrap {
  text-align: center;
  margin-top: 1.52rem;
}
.content-wrap .editWrap .approveWrap p img {
  width: 1.66rem;
}
.content-wrap .editWrap .approveWrap p {
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #a6a5a2;
}
.content-wrap .editWrap .step-wrap {
  text-align: center;
  min-height: 6.95rem;
}
.content-wrap .editWrap .step-wrap .step-p {
  height: 0.3rem;
  line-height: 0.3rem;
  /*background: #fcfbfb;*/
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #A6A5A2;
  margin-bottom: 0.6rem;
}
.content-wrap .editWrap .step-wrap .step-p span {
  display: inline-block;
}
/*.content-wrap .editWrap .step-wrap .approveStep1 {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  padding: 0 2.4rem;*/
/*  margin-bottom: 1.33rem;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep1 .approveWrap {*/
/*  width: 3rem;*/
/*  height: 3.72rem;*/
/*  border-radius: 0.02rem;*/
/*  border: 0.01rem solid #e6e5e3;*/
/*  margin-top: 1rem;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep1 .approveWrap img {*/
/*  width: 1.36rem;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep1 .approveWrap p {*/
/*  font-size: 0.14rem;*/
/*  line-height: 0.2rem;*/
/*  color: #a6a5a2;*/
/*  margin-bottom: 0.2rem;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep1 .approveWrap span {*/
/*  color: #4d4a4a;*/
/*  font-size: 0.2rem;*/
/*  line-height: 0.28rem;*/
/*  margin-bottom: 0.04rem;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep2 {*/
/*  width: 3.6rem;*/
/*  margin-top: 0.66rem;*/
/*  margin: 0 auto;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep2 .uploadImg {*/
/*  margin-left: 0.16rem;*/
/*}*/
/*.content-wrap .editWrap .step-wrap .approveStep2 .uploadImg .cover-uploader {*/
/*  width: 2.4rem;*/
/*  height: 1.5rem;*/
/*}*/
.content-wrap .editWrap .step-wrap .approveStep2 .uploadImg .cover-uploader .imgUploadText i {
  display: block;
  margin: 0 auto;
  margin-bottom: 0.12rem;
  border-radius: 0.02rem;
  background: url('../images/uploadImg.png') no-repeat;
  background-size: 100%;
  width: 1.05rem;
  height: 0.74rem;
}
.content-wrap .editWrap .step-wrap .approveStep2 p {
  font-size: 0.14rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #242323;
  margin-bottom: 0.28rem;
}
.content-wrap .editWrap .step-wrap .approveStep2 p input {
  outline: none;
  border-radius: 0.02rem;
  border: 0.01rem solid #e6e5e3;
  width: 2.4rem;
  height: 0.36rem;
  padding-left: 0.1rem;
}
.content-wrap .editWrap .step-wrap .approveStep3 {
  margin-top: 1.6rem;
}
.content-wrap .editWrap .step-wrap .approveStep3 img {
  width: 1.41rem;
}
.content-wrap .editWrap .step-wrap .approveStep3 span {
  font-size: 0.2rem;
  font-weight: 500;
  color: #4d4a4a;
}
.content-wrap .editWrap .step-wrap .approveStep3 p {
  font-size: 0.14rem;
  color: #a6a5a2;
}
.content-wrap .editWrap .vedioWrap {
  margin-top: 0.2rem;
}
.content-wrap .editWrap .vedioWrap video {
  width: 4.8rem;
  height: 2.7rem;
  margin-left: 0.58rem;
}
.content-wrap .editWrap .vedioWrap .p {
  margin-left: 0.5rem;
}
.content-wrap .editWrap .vedioWrap .p span {
  margin-left: 0.2rem;
}
/* .content-wrap .editWrap .vedioWrap .p input {
  height: 0.44rem;
  padding: 0;
  padding-left: 0.1rem;
  border: none;
  outline: none;
  border-radius: 0.04rem;
  border: 0.01rem solid #e6e5e3;
} */
.content-wrap .editWrap .vedioWrap .uploadVideo {
  margin-left: 0.5rem;
  margin-top: 0.28rem;
  display: flex;
  flex-direction: row;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .uploadVideoChild {
  display: flex;
  flex-direction: column ;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .dragEle {
  display: inline-block;
  margin-bottom: 0;
  border-radius: 0.02rem;
  width: 4.8rem;
  height: 2.7rem;
  text-align: center;
  font-size: 0.14rem;
  position: relative;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .dragEle strong {
  display: block;
  margin: 0 auto;
  margin-bottom: 0.12rem;
  border-radius: 0.02rem;
  background: url('../images/uploadVideo.png') no-repeat;
  background-size: 100%;
  width: 1.02rem;
  height: 0.98rem;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .dragEle input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 4.8rem;
  height: 2.7rem;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .dragEle span {
  font-size: 0.12rem;
  color: #a6a5a2;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .dragEle .compressNode {
  display: block;
  font-size: 0.12rem;
  margin-top: 0.28rem;
}
.content-wrap .editWrap .vedioWrap .uploadVideo .dragEle a {
  color: #FFDF99;
  text-decoration: none;
}
.content-wrap .editWrap .preViewArticle {
  position: relative;
  width: 4.8rem;
  height: 4.5rem;
  background: #ffffff;
  margin-left: 0.58rem;
}
.content-wrap .editWrap .preViewArticle .proviewSpan {
  position: absolute;
  display: inline-block;
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #4d4a4a;
  width: 0.24rem;
  height: 0.52rem;
  background: #f1f1f1;
  border: 0.01rem solid #cccccc;
  border-right: null;
  text-align: center;
  left: -0.24rem;
}
.content-wrap .appdownload {
  height: 100%;
  width: 100%;
  background: url('../images/appdown.png');
  background-size: 100% 100%;
  overflow: hidden;
}
.content-wrap .appdownload .qrWrap {
  width: 3.2rem;
  height: 3.98rem;
  background: rgba(0, 0, 0, 0.5);
  border: 0.02rem solid rgba(255, 177, 0, 0.5);
  margin: 2rem 0 0 5.6rem;
  text-align: center;
  position: relative;
}
.content-wrap .appdownload .qrWrap .node {
  font-size: 0.14rem;
  color: rgba(230, 229, 227, 0.5);
  margin-top: 0.06rem;
  margin-bottom: 0;
}
.content-wrap .appdownload .qrWrap h3 {
  margin-top: 0.1rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #f2f2f0;
  padding: 0;
}
.content-wrap .appdownload .qrWrap p {
  font-size: 0.2rem;
  font-weight: 500;
  color: #f2f2f0;
}
.content-wrap .appdownload .qrWrap img {
  width: 1.8rem;
  margin-top: 0.6rem;
}
/** iPhone **/

@media only screen and (min-width: 320px) and (max-width: 767px){
  .headerWrap {
    height: 64px !important;
    padding: 18px 16px !important;
    justify-content: space-between;
  }
  .headerWrap .logoBox {
    width: 70px !important;
    height: 28px !important;
    margin: 0 !important;
    background-image: url(../images/多多自走棋png@3x.png);
    background-size: 100% 100%;
  }
  .headerWrap .logoBox .logoImg {
    display: none;
  }
  .headerWrap .div {
    display: none !important;
  }
  .headerWrap .mobileItem {
    width: 108px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .headerWrap .mobileItem .item{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: 100% 100%;
  }
  .ch{
    background-image: url(../images/英文1备份@3x.png);
  }
  .en{
    background-image: url(../images/英文1@3x.png);
  }
  .user{
    background-image: url(../images/登录@3x.png);
  }
}