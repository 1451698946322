@base: 100;
.steps {
  margin: 0 auto;
  width: 600rem/@base;
  display: flex;
  align-items: center;
  font-size: 16rem/@base;
  font-weight: bold;

  .steps-item {
    display: inline-flex;

    .step {
      width: .24rem;
      height: .24rem;
      line-height: .24rem;
      border-radius: 50%;
      border: 1px solid #33353D;

      text-align: center;
      color: #33353D;
    }

    .step-color {
      background: #FDCA40;
    }

    span {
      margin-left: .08rem;
    }
  }

  .steps-hr {
    width: .68rem;
    height: 1px;
    border: 1px solid #A2A6B8;
    margin: 0 16rem/@base;
  }

}