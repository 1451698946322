.show {
  display: block;
}
.hidden {
  display: none;
}
.h5Style {
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFangSC;
  font-weight: 500;
  color: "rgba(36,35,35,1)";
  line-height: ".25rem";
  margin-bottom: ".1rem";
}
.descriptionP {
  font-size: 0.14rem;
  font-family: PingFangSC-Regular, PingFangSC;
  font-weight: 400;
  color: #4d4a4a;
  line-height: 0.21rem;
}
.quickLogin {
  position: relative;
  margin-top: 0.6rem;
  height: 1.3rem;
  padding: 0.1rem 0.3rem 0;
}
.quickLogin i {
  display: block;
  width: 100%;
  border-top: 0.01rem solid #e6e5e3;
}
.quickLogin p {
  width: 0.9rem;
  position: absolute;
  left: 50%;
  top: 0.02rem;
  background-color: white;
  transform: translateX(-50%);
  text-align: center;
  height: 0.17rem;
  font-size: 0.12rem;
  font-family: PingFangSC-Regular, PingFangSC;
  color: #a6a5a2;
  line-height: 0.17rem;
}
.quickLogin div {
  display: flex;
  justify-content: space-around;
  margin: 0.28rem 0.4rem;
}
.quickLogin div img {
  width: 0.52rem;
  cursor: pointer;
}

.contentWrap {
  position: relative;
  width: 100%;
  height: 94.8vh;
  background: url(../images/bg_1.png);
  background-size: 100% 100%;
}
.loginBtn {
  position: absolute;
  width: 3.2rem;
  height: .7rem;
  background: url(../images/login-btn.png);
  background-size: 100% 100%;
  right: 4.2rem;
  bottom: 1.8rem;
  cursor: pointer;
}
#loginDiv {
  /*background-color: #fff;*/
  background-image: url("../images/login_bg.png");
  width: 6rem;
  height: 3.7rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
#loginDiv .login-text{
  position: absolute;
  font-weight: bold;
  font-size: 0.24rem;
  height: 0.33rem;
  top: 0.3rem;
  left:0;
  right: 0;
  margin: auto;
}
/** iPhone **/
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .contentWrap {
    height: 92.1vh;
    background: url(../images/明星作者-培养计划背景@3x.png);
    background-size: 100% 100%;
  }
  .loginBtn {
    width: 195px;
    height: 91px;
    background: url(../images/按钮@3x.png);
    background-size: 100% 100%;
    left: 55%;
    transform: translateX(-50%);
    bottom: 13.5%;
  }
  #loginDiv {
    display: none;
  }
}
